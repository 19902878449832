import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImageViewer } from "react-image-viewer-hook";
import Resizer from "react-image-file-resizer";

// import { ReactComponent as CheckIcon } from "../assets/CheckIcon.svg";
import { ReactComponent as CloseIcon } from "../assets/CloseIcon.svg";
import { ReactComponent as SpinnerIcon } from "../assets/SpinnerIcon.svg";

import {
  clickInvite,
  createFileUploadURL,
  uploadFile,
} from "../api/FileUploadAPI";

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

const MAX_IMG_WIDTH = 1000;
const MAX_IMG_HEIGHT = 1500;

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      MAX_IMG_WIDTH,
      MAX_IMG_HEIGHT,
      "JPEG",
      95,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const FileUpload = () => {
  const navigate = useNavigate();
  const { inviteId } = useParams();
  const [imageFiles, setImageFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [uploadBtn, setUploadBtn] = useState("Upload");
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const { getOnClick, ImageViewer } = useImageViewer();

  const handleRemoveFile = (index) => {
    // console.log("removeFile", index);
    setImages([
      ...images.slice(0, index),
      ...images.slice(index + 1, images.length),
    ]);
    setImageFiles([
      ...imageFiles.slice(0, index),
      ...imageFiles.slice(index + 1, imageFiles.length),
    ]);
  };

  const handleFileOnChange = async (e) => {
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // console.log("File", file);
      if (file.type.match(imageTypeRegex)) {
        // console.log("Orig file", file);
        const resizedFile = await resizeFile(file);
        // console.log("Resized file", resizedFile);
        validImageFiles.push(resizedFile);
      }
    }

    if (validImageFiles.length) {
      setImageFiles((imageFiles) => [...imageFiles, ...validImageFiles]);
      return;
    }
  };

  const handleError = (ex) => {
    console.error("handleError", ex);
    navigate("/expired");
  };

  const uploadHandler = async (e) => {
    try {
      setUploading(true);
      setUploadBtn("Please wait ...");
      for (let i = 0; i < imageFiles.length; i++) {
        setUploadStatus(
          `Uploading ${imageFiles.length} document${
            imageFiles.length > 1 ? "s" : ""
          } - Less than 1 minute left`
        );
        const fileMetadata = await createFileUploadURL(
          inviteId,
          imageFiles[i].name
        );

        // console.log("fileMetadata", fileMetadata);
        // console.log("orig image", imageFiles[i]);
        // const resizedImage = await resizeFile(imageFiles[i]);
        // console.log("resized image", resizedImage);

        await uploadFile(fileMetadata.uploadUrl, imageFiles[i]);
      }
    } catch (ex) {
      handleError(ex);
    } finally {
      setTimeout(() => {
        setUploadCompleted(true);
        setUploadBtn("Upload");
        setImageFiles([]);
        setImages([]);
        setUploading(false);
        setUploadStatus("");
      }, 3000);
    }
  };

  useEffect(() => {
    // click
    try {
      clickInvite(inviteId);
    } catch (ex) {
      navigate("/expired");
    }
  }, [inviteId]);

  useEffect(() => {
    const images = [],
      fileReaders = [],
      newURLS = [];
    let isCancel = false;
    if (imageFiles.length) {
      // console.log(imageFiles);
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
            newURLS.push(URL.createObjectURL(file));
          }
          if (images.length === imageFiles.length && !isCancel) {
            setImages(images);
            setImageURLs(newURLS);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  return (
    <div className="mb-6 px-4">
      {uploadCompleted ? (
        <UploadCompletedDialog
          uploadClickHandler={() => setUploadCompleted(false)}
        />
      ) : (
        <div className="mx-8 p-8 rounded-xl bg-white shadow-sm">
          {/* <p className="mb-2 text-gray-600">Hi {invite?.name},</p> */}
          <p className="mb-6 text-gray-600">
            {/* Hoag sent you this request to upload your document: */}
            You are now on a secure website where you can securely upload and
            send an image of your order to Hoag.
          </p>
          {/* <p className="mb-8 block text-lg font-semibold text-[#07074D]">
            Radiology & Imaging Order Form
          </p> */}

          <div className="mb-8">
            <label
              htmlFor="file"
              // class="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
            >
              <div>
                <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#005e92] cursor-pointer">
                  Add {`${imageFiles.length > 0 ? "more" : ""}`} files
                </span>
                {/* <span class="mb-2 block text-xl font-semibold text-[#07074D]">
                  or drag files here
                </span>
                <span class="mb-2 block text-base font-medium text-[#6B7280]">
                  or
                </span> */}
              </div>
            </label>
            <input
              type="file"
              id="file"
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={handleFileOnChange}
              accept="image/png, image/jpg, image/jpeg"
              multiple
              className="sr-only"
              disabled={uploadBtn !== "Upload"}
            />
          </div>

          {images.length > 0 ? (
            <div>
              <>
                {images.map((image, idx) => {
                  return (
                    <div
                      key={idx}
                      className="mb-5 rounded-md bg-[#F5F7FB] py-4 px-8"
                    >
                      <div className="flex items-center justify-between">
                        <span>
                          <a
                            key={image}
                            href={imageURLs[idx]}
                            onClick={getOnClick(`${imageURLs[idx]}`)}
                          >
                            <img
                              src={image}
                              className="h-10"
                              alt={`${imageFiles[idx].name} file preview`}
                            />
                          </a>
                        </span>
                        <span className="truncate pr-3 text-base font-medium text-[#005e92]">
                          {imageFiles[idx].name}
                        </span>
                        {uploading && (
                          <SpinnerIcon className="animate-spin h-5 w-5 text-[#005e92]" />
                        )}
                        {/* {uploadingStatus[idx] === "uploaded" && (
                          <CheckIcon className="h-5 w-5 text-[#005e92]" />
                        )} */}
                        {!uploading && (
                          <button
                            className="text-[#005e92]"
                            onClick={() => handleRemoveFile(idx)}
                          >
                            <CloseIcon className=" h-5 w-5 text-[#005e92]" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                <ImageViewer />
              </>
            </div>
          ) : null}
          {images.length > 0 ? (
            <>
              <button
                className={`hover:shadow-form rounded-md bg-[#005e92] mt-4 py-3 px-8 text-center text-base font-semibold text-white outline-none disabled:opacity-50 ${
                  uploading ? "cursor-wait" : ""
                }`}
                disabled={uploading}
                onClick={uploadHandler}
              >
                {uploadBtn}
              </button>
              <p className="mt-2 text-gray-600">{uploadStatus}</p>
            </>
          ) : (
            ""
          )}
        </div>
      )}
      <p className="mx-10 my-4 text-sm text-gray-400">
        If you wish to stop this process and schedule your appointment in
        another way, please notify the scheduler right away.
      </p>
      <p className="mx-10 text-sm text-gray-400">
        By uploading an image of the order, you are acknowledging that you are
        the patient or the patient’s parent/legal guardian and/or another
        individual authorized by the patient to send the information on the
        patient’s behalf.
      </p>
      <p className="mt-8 text-sm text-gray-400">
        &copy; {new Date().getFullYear()} Hoag, All Rights Reserved.
      </p>
    </div>
  );
};

const UploadCompletedDialog = ({ uploadClickHandler }) => {
  return (
    <div className="mx-8 mt-4 p-8 rounded-xl bg-white shadow-sm ease-in">
      <h2 className="block text-xl font-semibold text-[#07074D]">
        Finished uploading
      </h2>
      <p className="mt-4 text-gray-600">
        Thank you for uploading the requested files.
      </p>
      <button
        className="hover:shadow-form rounded-md bg-[#005e92] mt-8 py-3 px-8 text-center text-base font-semibold text-white outline-none"
        onClick={uploadClickHandler}
      >
        Upload more files
      </button>
    </div>
  );
};

const errorDialog = () => {
  return (
    <div className="mx-8 mt-4 p-8 rounded-xl bg-white shadow-sm ease-in">
      <h2 className="block text-xl font-semibold text-[#07074D]">Sorry</h2>
      <p className="mt-4 text-gray-600">The link has expired</p>
    </div>
  );
};
